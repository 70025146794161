import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useMetrics } from "../../page-components/projects/metrics"
import {
  LocationTemplateProps,
  Template,
} from "../../page-components/projects/template"
import { imgFinder } from "../../page-components/projects/5-floor-plan"
import ProjectDetails from "../../images/projects/BF.yml"

const BloomfieldPark = () => {
  const { logo, siteDevImg, floorPlans } = useStaticQuery(graphql`
    query(
      $floorPlan: String = "/images/projects/bloomfield-park/04 FLOOR PLANS/"
    ) {
      logo: file(relativePath: { eq: "bf-park-logo.png" }) {
        ...ImgL
      }
      siteDevImg: file(
        relativePath: { eq: "projects/bloomfield-park/BF PARK SDP.png" }
      ) {
        ...SiteDevImg
      }
      ...FloorPlans
    }
  `)
  const fpImg = imgFinder(floorPlans)
  const metrics = useMetrics([
    { metric: "TownhouseUnits", num: "234" },
    { metric: "TypesofUnits", num: "4" },
    { metric: "TypicalLotArea", num: "38 - 70" },
    { metric: "TypicalFloorArea", num: "35 - 54" },
  ])
  const props: LocationTemplateProps = {
    title: "Bloomfield Park",
    logo,
    location: "Brgy. Bical, Mabalacat City, Pampanga",
    metrics,
    carouselImgs: [
      require("../../images/projects/bloomfield-park/01 BF Park Single Attached.jpg"),
      require("../../images/projects/bloomfield-park/02 BF Park Townhouse A.jpg"),
      require("../../images/projects/bloomfield-park/03 BF Park Townhouse B End.jpg"),
      require("../../images/projects/bloomfield-park/04 BF Park Townhouse B Inner.jpg"),
    ],
    siteDev: {
      img: siteDevImg,
      txt:
        "Carefully planned for easy maneuvering and wayfinding, Bloomfield Park is grouped in clusters according to townhouse type for a more uniform and aesthetically pleasing look. Equipped with its own set of amenities, such as its pool area, clubhouse, basketball court, playground, and chapel, BF Park caters to all your needs for happy living.",
    },
    amenities: {
      description:
        "Find endless possibilities and create beautiful memories with your family with the several amenities that Bloomfield Park has to offer. Fully equipped with a clubhouse, swimming pool, basketball court, playground, chapel, and learning center - BF Park makes everything easily accessible and within reach for all its users.      ",
      items: [
        {
          name: "Clubhouse Exterior",
          description:
            "The open-air Clubhouse is ready for anyone's use for events, gatherings, and other activities. It’s surrounded by other amenities which makes it easy to go from one activity to another",
          img: require("../../images/projects/bloomfield-park/amenities/01 BF PARK CLUBHOUSE.jpg"),
        },
        {
          name: "Swimming Pool",
          description:
            "Enjoy the sun and have a relaxing time by the pool area.  ",
          img: require("../../images/projects/bloomfield-park/amenities/02 BF PARK SWIMMING POOL.jpg"),
        },
        {
          name: "Basketball Court",
          description:
            "Enjoy the outdoors while having fun with physical activities such as a fun game of basketball.",
          img: require("../../images/projects/bloomfield-park/amenities/03 BF PARK BASKETBALL COURT.jpg"),
        },
        {
          name: "Playground",
          description:
            "Endless fun and imagination is possible with your family at the playground.",
          img: require("../../images/projects/bloomfield-park/amenities/04 BF PARK PLAYGROUND.jpg"),
        },
        {
          name: "Chapel",
          description:
            "Find inner peace in the chapel wherein you can set your intentions in a safe and sacred space.",
          img: require("../../images/projects/bloomfield-park/amenities/05 BF PARK CHAPEL.jpg"),
        },
        {
          name: "Pedro Y. Laxa Learning Center",
          description: "Gain knowledge in the learning center.",
          img: require("../../images/projects/bloomfield-park/amenities/06 BF PARK LEARNING CENTER.jpg"),
        },
      ],
    },
    floorPlans: {
      description:
        "Bloomfield Park has four (4) types of units to offer. (1) Townhouse A - stands at two-storeys with a typical lot area ranging from 38-51sq.m; (2) Single Attached - a bungalow type unit with a typical lot area of 70sq.m; (3) Townhouse B - stands at two-storeys with a typical lot area ranging from 38-51.4sq.m; and (4) Townhouse C - a two-storey unit equipped with a store space with a typical lot area ranging from 38-52sq.m \n Our units give you the freedom and the power to create your home the way you want to. ** All Floor Plans below are for suggested layout only.",
      items: [
        [
          {
            name: "Townhouse A (End Unit)",
            fpImg: fpImg("BF PARK THA END FP"),
            pImg: fpImg("BF PARK THA END PERSP"),
          },
          {
            name: "Townhouse A (Inner Unit)",
            fpImg: fpImg("BF PARK THA INNER FP"),
            pImg: fpImg("BF PARK THA INNER PERSP"),
          },
          {
            name: "Single Attached",
            fpImg: fpImg("BF PARK SA FP"),
            pImg: fpImg("BF PARK SA PERSP"),
          },
          {
            name: "Townhouse B (End Unit)",
            fpImg: fpImg("BF PARK THB END FP"),
            pImg: fpImg("BF PARK THB END PERSP"),
          },
          {
            name: "Townhouse B (Inner Unit)",
            fpImg: fpImg("BF PARK THB INNER FP"),
            pImg: fpImg("BF PARK THB INNER PERSP"),
          },
          {
            name: "Townhouse C (End Unit)",
            fpImg: fpImg("BF PARK THC END FP"),
            pImg: fpImg("BF PARK THC END PERSP"),
          },
          {
            name: "Townhouse C (Inner Unit)",
            fpImg: fpImg("BF PARK THC INNER FP"),
            pImg: fpImg("BF PARK THC INNER PERSP"),
          },
        ],
      ],
    },
    modelUnits: [
      require("../../images/projects/bloomfield-park/05 MODEL UNIT/01 BF PARK FOYER_LIVING_DINING.jpg"),
      require("../../images/projects/bloomfield-park/05 MODEL UNIT/02 BF T_B.jpg"),
      require("../../images/projects/bloomfield-park/05 MODEL UNIT/03 BF STAIRS.jpg"),
      require("../../images/projects/bloomfield-park/05 MODEL UNIT/04 BF PARK BEDROOM1.jpg"),
      require("../../images/projects/bloomfield-park/05 MODEL UNIT/05 BF PARK MASTER BEDROOM.jpg"),
    ],
    projDetails: {
      ...ProjectDetails,
      name: "Bloomfield Park",
      description:
        "Bloomfield Park is the second phase of the Bloomfield Development located in Mabalacat City, Pampanga. Bloomfield boasts its affordability and strategic location. It has close proximity to several establishments, and contains several in house amenities that makes Bloomfield your dream location.",
    },
  }
  return <Template {...props} />
}

export default BloomfieldPark
